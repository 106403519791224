<template>
  <div class="grid">
    
    <div
      v-permission="'missives.create'"
      class="flex flex-wrap-reverse items-center"
    >
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>

    <vx-card>
      <missive-grid :service="service"/>
    </vx-card>
  </div>
</template>

<script>
import MissiveService from '@/services/api/MissiveService'
import MissiveGrid from './index/MissiveGrid.vue'

export default {
  components: { MissiveGrid },

  data: () => ({
    service: null
  }),

  beforeMount() {
    this.service = MissiveService.build(null)
  },

  methods: {
    addNew() {
      this.$router.push('/missives/create')
    }
  }
}
</script>

<style>
</style>