var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: "missives.create",
              expression: "'missives.create'",
            },
          ],
          staticClass: "flex flex-wrap-reverse items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
              on: { click: _vm.addNew },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
              }),
              _c("span", { staticClass: "ml-2 text-base text-primary" }, [
                _vm._v(_vm._s(_vm.$t("AddNew"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "vx-card",
        [_c("missive-grid", { attrs: { service: _vm.service } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }