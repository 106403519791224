<template>
  <Grid
    v-if="service"
    :hide_actions="false"
    :service="service"
    route_name="missives"
    :column_formats="{
      created_at: (val) => {
        return $utils.format.date(val)
      },
    }"
  />
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      default: null
    }
  }
}
</script>

<style>

</style>